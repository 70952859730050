<template>
  <div id="access-partner">
    <h2 aria-label="Access Partner">
      Access Partner
    </h2>
    <b-form
      class="form-container"
      @submit.prevent
    >
      <FormProgressBar :progress="progress()" />
      <!-- ERRORS -->
      <FormErrors />
      <div class="form">
        <FormSection
          description="Welcome to our Access Partner Sign-up Portal. Through this sign up page, our new and existing Access Partners
        may sponsor a Site Access of their choice for a number of days. Once submitted, please allow us 24 to 48 hours to
        review the request."
        />

        <!-- ===== EMAIL VERIFICATION ===== -->
        <FormSection
          title="Email Verification"
          description="Please provide your email. We need this so we can verify your purchase and send a confirmation message to your email address."
        >
          <FormGroup
            for="dummy"
            label="Dummy*"
            class="w-50"
            hidden
          >
            <b-input
              name="Dummy"
              bind-id="dummy"
            />
          </FormGroup>

          <FormGroup
            for="email"
            label="Email Address"
            class="w-100"
          >
            <FormInput
              ref="email"
              v-validate="{ required: true, email: true }"
              :disabled="disabled"
              :state="validateState('Email')"
              name="Email"
              bind-id="email"
              autocomplete="email"
              type="email"
              get="email"
              set="UPDATE_EMAIL"
              invalid-feedback="Please enter a valid Email."
              invalid-feedback-id="email-invalid-feedback"
              required
              @keyup.enter.native="sendCode"
            />
          </FormGroup>
          <b-button
            v-if="!emailVerified"
            id="email-verify-button"
            :disabled="disabled || emailVerified"
            variant="primary"
            class="w-75"
            @click="sendCode"
          >
            Verify
          </b-button>
        </FormSection>
        <FormSection
          v-if="verifying"
          :description="`Enter the 4-digit code texted to you at [${email}]`"
        >
          <FormGroup
            id="verification-code"
            :state="state"
            :class="[{'invalid': state}]"
            :invalid-feedback="invalidFeedback"
            for="auth"
            role="alert"
          >
            <b-input
              id="verification-code-field"
              v-model="auth"
              type="tel"
              maxlength="4"
            />
          </FormGroup>
        </FormSection>
        <b-button
          v-if="verifying"
          :disabled="disabled"
          class="link-button"
          size="sm"
          variant="link"
          @click="sendCode"
        >
          {{ resendCodeText }}
        </b-button>
        <b-button
          v-if="verifying"
          class="link-button"
          size="sm"
          variant="link"
          @click="back"
        >
          Back
        </b-button>
        <!-- OPTIONS -->
        <FormSection
          v-if="emailVerified"
          title="Start Date"
        >
          <div class="d-flex justify-content-between">
            <FormGroup
              for="startYear"
              label="Year"
              class="w-50"
            >
              <FormSelect
                v-model="startYear"
                v-validate="{ required: true }"
                :state="validateState('Start Year')"
                :options="startYearOptions"
                name="Start Year"
                bind-id="startYear"
                invalid-feedback="Start Year is a required field."
                invalid-feedback-id="start-year-invalid-feedback"
                get="startYear"
                set="UPDATE_START_YEAR"
                required
              />
            </FormGroup>
            <FormGroup
              for="startMonth"
              label="Month"
              class="w-50"
            >
              <FormSelect
                v-model="startMonth"
                v-validate="{ required: true }"
                :state="validateState('Start Month')"
                :options="startMonthOptions"
                name="Start Month"
                bind-id="startMonth"
                invalid-feedback="Start Month is a required field."
                invalid-feedback-id="start-month-invalid-feedback"
                get="startMonth"
                set="UPDATE_START_MONTH"
                required
              />
            </FormGroup>
          </div>
          <div class="d-flex justify-content-between">
            <FormGroup
              for="startDay"
              label="Day"
              class="w-50"
            >
              <FormSelect
                v-model="startDay"
                v-validate="{ min: 1 }"
                :state="validateState('Start Day')"
                :options="startDayOptions"
                name="Start Day"
                bind-id="startDay"
                invalid-feedback="Start Day is a required field."
                invalid-feedback-id="start-day-invalid-feedback"
                get="startDay"
                set="UPDATE_START_DAY"
                required
              />
            </FormGroup>
            <FormGroup
              for="startHour"
              label="Hour (Your timezone)"
              class="w-50"
            >
              <FormSelect
                v-model="startHour"
                v-validate="{ required: true, min: 0 }"
                :state="validateState('Start Hour')"
                :options="startHourOptions"
                name="Hour (Your timezone)"
                bind-id="startHour"
                invalid-feedback="Hour is a required field."
                invalid-feedback-id="start-hour-invalid-feedback"
                get="startHour"
                set="UPDATE_START_HOUR"
                required
              />
            </FormGroup>
          </div>
        </FormSection>
        <FormSection
          v-if="daysOptions && emailVerified"
          title="Number of Days"
        >
          <FormGroup for="daysOptions">
            <FormSelect
              v-model="daysSelected"
              v-validate="{ min: 1 }"
              :state="validateState('Number of Days')"
              :options="daysOptions"
              name="Number of Days"
              bind-id="daysOptions"
              default=""
              invalid-feedback="Number of Days is a required field."
              invalid-feedback-id="days-options-invalid-feedback"
              get="daysSelected"
              set="SET_DAYS_SELECTED"
              required
            />
          </FormGroup>
        </FormSection>
        <!-- SITE ADDRESS -->
        <FormSection
          v-if="emailVerified"
          title="Business Information"
        >
          <div class="d-flex justify-content-between">
            <FormGroup
              for="siteFirstName"
              label="First Name*"
              class="w-50"
            >
              <FormInput
                :state="validateState('First Name')"
                name="First Name"
                bind-id="siteFirstName"
                invalid-feedback="First name required."
                invalid-feedback-id="site-first-name-invalid-feedback"
                get="siteFirstName"
                set="UPDATE_SITE_FIRST_NAME"
                required
              />
            </FormGroup>
            <FormGroup
              for="siteLastName"
              label="Last Name*"
              class="w-50"
            >
              <FormInput
                :state="validateState('Last Name')"
                name="Last Name"
                bind-id="siteLastName"
                invalid-feedback="Last name required."
                invalid-feedback-id="site-last-name-invalid-feedback"
                get="siteLastName"
                set="UPDATE_SITE_LAST_NAME"
                required
              />
            </FormGroup>
          </div>
          <FormGroup
            for="accountName"
            label="Company Name*"
            class="w-100"
          >
            <FormInput
              v-validate="{ required: true }"
              :state="validateState('Company Name')"
              name="Company Name"
              bind-id="accountName"
              invalid-feedback="Company name required."
              invalid-feedback-id="company-name-invalid-feedback"
              get="accountName"
              set="UPDATE_ACCOUNT_NAME"
              required
            />
          </FormGroup>
          <FormGroup
            for="siteAddress1"
            label="Site Address*"
            class="w-100"
          >
            <FormInput
              v-validate="{ required: true }"
              :state="validateState('Site Address')"
              name="Site Address"
              bind-id="siteAddress1"
              invalid-feedback="Site address required."
              invalid-feedback-id="site-address1-invalid-feedback"
              get="siteAddress1"
              set="UPDATE_SITE_ADDRESS1"
              required
            />
          </FormGroup>
          <div class="d-flex justify-content-between">
            <FormGroup
              for="siteCountry"
              label="Site Country*"
              class="w-50"
            >
              <FormSelect
                :options="countryOptions"
                :state="null"
                name="site country"
                bind-id="siteCountry"
                invalid-feedback="Site country required."
                invalid-feedback-id="site-country-invalid-feedback"
                get="siteCountry"
                set="UPDATE_SITE_COUNTRY"
                required
              />
            </FormGroup>
            <FormGroup
              for="siteState"
              label="Site State/Province/Region*"
              class="w-50"
            >
              <FormSelect
                :options="siteStateOptions"
                :state="null"
                name="site state"
                bind-id="siteState"
                invalid-feedback="Site state required."
                invalid-feedback-id="site-state-invalid-feedback"
                get="siteState"
                set="UPDATE_SITE_STATE"
                required
              />
            </FormGroup>
          </div>
          <div class="d-flex justify-content-between">
            <FormGroup
              for="siteCity"
              label="Site City*"
              class="w-50"
            >
              <FormInput
                v-validate="{ required: true }"
                :state="validateState('Site City')"
                name="Site City"
                bind-id="siteCity"
                invalid-feedback="Site city required."
                invalid-feedback-id="site-city-invalid-feedback"
                get="siteCity"
                set="UPDATE_SITE_CITY"
                required
              />
            </FormGroup>
            <FormGroup
              for="siteZip"
              label="Site Zip or Postal Code*"
              class="w-50"
            >
              <FormInput
                v-validate="{ required: true }"
                :state="validateState('Site Zip')"
                name="Site Zip"
                bind-id="sizeZip"
                invalid-feedback="Site Zip or Postal Code Required."
                invalid-feedback-id="site-zip-invalid-feedback"
                get="siteZip"
                set="UPDATE_SITE_ZIP"
                required
              />
            </FormGroup>
          </div>
        </FormSection>

        <!-- Payment Profile -->
        <FormSection
          v-if="ccLastFour && cardType && emailVerified"
          :description="`${cardType} ending in ${ccLastFour}`"
          title="Payment Method"
        >
          <FormGroup
            for="isManualPayment"
          >
            <FormInput
              bind-id="isManualPayment"
              type="checkbox"
              label="Use a different payment method"
              get="isManualPayment"
              set="TOGGLE_IS_MANUAL_PAYMENT"
              class="w-100"
            />
          </FormGroup>
        </FormSection>

        <!-- recurly script -->
        <div v-show="!ccLastFour && emailVerified">
          <script2 src="https://js.recurly.com/v4/recurly.js" />
          <script2 src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
          <!-- eslint-disable -->
          <script2>
            $(document).ready(function () {
              recurly.configure({
                publicKey: '{{publicKey}}',
              });
            });

            recurly.on('field:submit', function (event) {
              event.preventDefault();
            });

            $('form').on('submit', function (event) {
              event.preventDefault();
              var form = this;

              recurly.token(form, function (err, token) {
                if (err) {
                  console.log(err);
                  alert(err.message + ' Please make sure your credit card number and expiration date are correct.');
                } else {
                  $('#token').val(token.id);
                  $('#billingButton').click();
                }
              });
            });

          </script2>
          <!-- eslint-enable -->
        </div>

        <form
          v-show="isManualPayment && emailVerified"
          :action="`/api/account/${accountCode}`"
          method="put"
          @submit.prevent
        >
          <!-- BILLING INFO -->
          <FormSection
            title="Billing Information"
          >
            <div class="d-flex justify-content-between">
              <FormGroup
                for="firstName"
                label="First Name*"
                class="w-50"
              >
                <FormInput
                  :state="validateState('Billing First Name')"
                  name="Billing First Name"
                  bind-id="firstName"
                  data-recurly="first_name"
                  invalid-feedback="Billing First Name Required."
                  invalid-feedback-id="billing-first-name-invalid-feedback"
                  get="ccFirstName"
                  set="UPDATE_CC_FIRST_NAME"
                  required
                />
              </FormGroup>
              <FormGroup
                for="lastName"
                label="Last Name*"
                class="w-50"
              >
                <FormInput
                  :state="validateState('Billing Last Name')"
                  name="Billing Last Name"
                  bind-id="lastName"
                  data-recurly="last_name"
                  invalid-feedback="Billing Last Name Required."
                  invalid-feedback-id="billing-last-name-invalid-feedback"
                  get="ccLastName"
                  set="UPDATE_CC_LAST_NAME"
                  required
                />
              </FormGroup>
            </div>
            <FormGroup
              for="address1"
              label="Address*"
              class="w-100"
            >
              <FormInput
                :state="validateState('Address')"
                name="Address"
                bind-id="address1"
                data-recurly="address1"
                invalid-feedback="Address Required."
                invalid-feedback-id="address1-invalid-feedback"
                get="address1"
                set="UPDATE_ADDRESS1"
                required
              />
            </FormGroup>
            <div class="d-flex justify-content-between">
              <FormGroup
                for="country"
                label="Country*"
                class="w-50"
              >
                <FormSelect
                  :options="countryOptions"
                  :state="null"
                  name="country"
                  bind-id="country"
                  data-recurly="country"
                  invalid-feedback="Country Required."
                  invalid-feedback-id="country-invalid-feedback"
                  get="country"
                  set="UPDATE_COUNTRY"
                  required
                />
              </FormGroup>
              <FormGroup
                for="state"
                label="State/Province/Region*"
                class="w-50"
              >
                <FormSelect
                  :options="stateOptions"
                  :state="null"
                  name="state"
                  bind-id="state"
                  data-recurly="state"
                  invalid-feedback="State Required."
                  invalid-feedback-id="state-invalid-feedback"
                  get="state"
                  set="UPDATE_STATE"
                  required
                />
              </FormGroup>
            </div>
            <div class="d-flex justify-content-between">
              <FormGroup
                for="city"
                label="City*"
                class="w-50"
              >
                <FormInput
                  :state="validateState('City')"
                  name="City"
                  bind-id="city"
                  data-recurly="city"
                  invalid-feedback="City Required."
                  invalid-feedback-id="city-invalid-feedback"
                  get="city"
                  set="UPDATE_CITY"
                  required
                />
              </FormGroup>
              <FormGroup
                for="zip"
                label="Zip or Postal Code*"
                class="w-50"
              >
                <FormInput
                  :state="validateState('Zip')"
                  name="Zip"
                  bind-id="zip"
                  data-recurly="postal_code"
                  invalid-feedback="Zip or Postal Code Required."
                  invalid-feedback-id="zip-invalid-feedback"
                  get="zip"
                  set="UPDATE_ZIP"
                  required
                />
              </FormGroup>
            </div>
          </FormSection>
          <FormSection
            title="Payment Information"
          >
            <FormGroup
              for="cc"
              label="Card Number*"
              class="w-100"
            >
              <div
                class="recurly-field"
                role="none"
                data-recurly="number"
              />
            </FormGroup>
            <div class="d-flex justify-content-between">
              <FormGroup
                for="expiryMonth"
                label="Expiration Month (MM)*"
                class="w-50"
              >
                <div
                  class="recurly-field"
                  role="none"
                  data-recurly="month"
                />
              </FormGroup>
              <FormGroup
                for="expiryYear"
                label="Expiration Year (YYYY)*"
                class="w-50"
              >
                <div
                  class="recurly-field"
                  role="none"
                  data-recurly="year"
                />
              </FormGroup>
            </div>
            <FormGroup
              for="cvv"
              label="CVV*"
              class="w-50"
            >
              <FormInput
                :state="null"
                name="CVV"
                bind-id="cvv"
                data-recurly="cvv"
                invalid-feedback="CVV Required."
                invalid-feedback-id="cvv-invalid-feedback"
                get="cvv"
                set="UPDATE_CVV"
                required
              />
            </FormGroup>
          </FormSection>
          <input
            id="token"
            ref="token"
            type="hidden"
            name="recurly-token"
            data-recurly="token"
          >
          <SignupOrderSummary v-if="orderSummary.length && orderReviewed" />
          <vue-recaptcha
            v-if="orderSummary.length && orderReviewed && emailVerified"
            id="recaptcha"
            ref="recaptcha"
            :load-recaptcha-script="true"
            :sitekey="recaptchaSiteKey"
            @verify="recaptchaVerify"
          />
          <!-- ===== ORDER SUMMARY ===== -->
          <b-btn
            v-if="!orderReviewed && isManualPayment && emailVerified"
            :disabled="previewButtonDisabled"
            type="submit"
            class="signup-buttons w-75"
            size="lg"
            variant="secondary"
          >
            <span v-if="previewButtonDisabled">
              <b-spinner label="processing" />
            </span>
            <span v-else>Get Order Summary</span>
          </b-btn>
          <b-btn
            id="billingButton"
            hidden
            @click="getOrderSummaryPreview()"
          />
        </form>
      </div>
      <div>
        <b-button
          v-if="!orderReviewed && ccLastFour && !isManualPayment"
          :disabled="previewButtonDisabled"
          class="signup-buttons w-75"
          size="lg"
          variant="secondary"
          @click="getOrderSummaryPreview()"
        >
          <span v-if="previewButtonDisabled">
            <b-spinner label="processing" />
          </span>
          <span v-else>Get Order Summary</span>
        </b-button>
        <b-button
          v-if="orderReviewed"
          :disabled="submitButtonDisabled"
          class="signup-buttons w-75"
          size="lg"
          variant="primary"
          @click="postAccessOrder()"
        >
          <span v-if="submitButtonDisabled">
            <b-spinner label="submitting" />
          </span>
          <span v-else>Submit</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import VueRecaptcha from 'vue-recaptcha';
import ValidationMixin from '../../mixin/ValidationMixin';

import FormErrors from '../Form/FormErrors.vue';
import FormProgressBar from '../Form/FormProgressBar.vue';
import FormSection from '../Form/FormSection.vue';
import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';
import FormSelect from '../Form/FormSelect.vue';
import SignupOrderSummary from '../Signup/SignupComponents/SignupOrderSummary.vue';

export default {
  name: 'AccessPartner',
  components: {
    VueRecaptcha,
    FormErrors,
    FormProgressBar,
    FormSection,
    FormGroup,
    FormInput,
    FormSelect,
    SignupOrderSummary,
  },
  mixins: [
    ValidationMixin,
  ],
  data() {
    return {
      prod: process.env.VUE_APP_ENV_NAME === 'prod',
      previewButtonDisabled: false,
      orderReviewed: false,
      submitButtonDisabled: false,
      emailVerifyButtonDisabled: false,
      emailVerified: false,
      emailVerifyStatus: null,
      token: '',
      publicKey: process.env.VUE_APP_RECURLY_PUBLIC_KEY,
      disabled: false,
      auth: '',
      verifying: false,
      timer: 0,
      state: null,
      invalidFeedback: '',
      recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA,
      recaptchaVerified: false,
    };
  },
  computed: {
    ...mapGetters([
      'errors',
      'accountCode',
      'daysOptions',
      'countryOptions',
      'countryCodeOptions',
      'stateOptions',
      'siteStateOptions',
      'daysSelected',
      'startDayOptions29',
      'startDayOptions30',
      'startDayOptions31',
      'startMonthOptions',
      'startYearOptions',
      'startHourOptions',
      'startDay',
      'startMonth',
      'startYear',
      'startHour',
      'email',
      'ccLastFour',
      'cardType',
      'orderSummary',
      'isManualPayment',
    ]),
    resendCodeText() {
      if (this.timer > 0) {
        return `Resend Code in ${this.timer}`;
      } else {
        return 'Resend Code';
      }
    },
    startDayOptions() {
      switch (this.startMonth) {
        case 2:
          return this.startDayOptions29;
        case 4:
        case 6:
        case 9:
        case 11:
          return this.startDayOptions30;
        default:
          return this.startDayOptions31;
      }
    },
  },
  watch: {
    auth(val) {
      if (val.length === 4) {
        this.verify();
      }
    },
    daysSelected() {
      this.orderReviewed = false;
    },
  },
  methods: {
    progress() { return { full: true }; },
    back() {
      this.verifying = false;
      this.disabled = false;
    },
    async sendCode() {
      if (this.errors.length) this.$store.commit('CLEAR_ERRORS');
      this.disabled = true;

      try {
        const validation = await this.$validator.validateAll();
        if (!validation) {
          const fieldErrors = await this.veeErrors.items.map((item) => item.msg);
          this.$store.commit('PUSH_ERRORS', fieldErrors);
          this.disabled = false;
          return;
        }
        const response = await this.$store.dispatch('postVerifyEmail');
        if (response) {
          this.verifying = true;
          this.state = null;
          this.invalidFeedback = '';
          this.minuteCountdown();
        } else {
          this.state = false;
          this.invalidFeedback = 'error';
          this.disabled = false;
        }
      } catch (error) {
        console.error(error);
        this.state = false;
        this.invalidFeedback = error.errorMessage;
        this.disabled = false;
      }
    },
    async verify() {
      if (this.errors.length) this.$store.commit('CLEAR_ERRORS');
      try {
        const response = await this.$store.dispatch('postVerifyEmailConfirm', this.auth);
        if (response) {
          if (response.newUser) {
            // create new guest account & login
            const payload = {
              email: this.email,
              verificationCode: response.verificationCode,
            };
            await this.$store.dispatch('postAccessPartner', payload);
            const login = await this.$store.dispatch('login', {
              login: this.email,
              password: response.verificationCode,
              authProvider: 'EMAIL_VERIFICATION',
              loginfrom: 'SSP',
            });
            if (login) {
              this.$store.commit('TOGGLE_IS_MANUAL_PAYMENT');
              this.emailVerified = true;
              this.verifying = false;
              this.state = true;
            }
          } else { // existing user
            // login and set guest
            const login = await this.$store.dispatch('login', {
              login: this.email,
              password: response.verificationCode,
              authProvider: 'EMAIL_VERIFICATION',
              loginfrom: 'SSP',
            });
            if (login) {
              await this.$store.dispatch('getUserBilling');
              if (!this.ccLastFour) this.$store.commit('TOGGLE_IS_MANUAL_PAYMENT');
              this.emailVerified = true;
              this.verifying = false;
              this.state = true;
            } else {
              this.state = false;
              this.invalidFeedback = 'error';
              this.auth = '';
            }
          }
        } else {
          this.state = false;
          this.invalidFeedback = 'error';
          this.auth = '';
        }
      } catch (error) {
        console.error(error);
        this.state = false;
        // this.invalidFeedback = `Error: ${error.errorMessage}`;
        this.invalidFeedback = 'Invalid. Try again.';
        this.auth = '';
      }
    },
    recaptchaVerify(response) {
      this.$store.dispatch('setRecaptchaToken', response);
      this.recaptchaVerified = true;
    },
    minuteCountdown() {
      this.disabled = true;
      this.timer = 60;
      const minuteTimer = setInterval(() => {
        this.timer -= 1;
        if (this.timer <= 0) {
          this.disabled = false;
          clearInterval(minuteTimer);
        }
      }, 1000);
    },
    async getOrderSummaryPreview() {
      if (this.errors.length) this.$store.commit('CLEAR_ERRORS');
      if (this.daysSelected === 0) {
        this.$store.commit('PUSH_ERRORS', ['Number of Days Selected needs to be more than 0']);
      }
      if (this.$refs.token.value) {
        this.$store.commit('UPDATE_RECURLY_CODE', this.$refs.token.value);
      }
      this.previewButtonDisabled = true;

      // do
      try {
        // validation
        const validation = await this.$validator.validateAll();

        if (!validation) {
          const fieldErrors = await this.veeErrors.items.map((item) => item.msg);
          this.$store.commit('PUSH_ERRORS', fieldErrors);
          this.previewButtonDisabled = false;
          return;
        }

        const response = await this.$store.dispatch('postAccessPartnerOrderSummary');
        if (response) {
          this.orderReviewed = true;
        }
        this.previewButtonDisabled = false;
      } catch (error) {
        console.error(error);
        this.previewButtonDisabled = false;
      }
    },
    async postAccessOrder() {
      this.submitButtonDisabled = true;
      if (this.errors.length) this.$store.commit('CLEAR_ERRORS');

      // do
      try {
        // validation
        const validation = await this.$validator.validateAll();

        if (!validation) {
          const fieldErrors = await this.veeErrors.items.map((item) => item.msg);
          this.$store.commit('PUSH_ERRORS', fieldErrors);
          this.submitButtonDisabled = false;
          return;
        } else {
          const response = await this.$store.dispatch('postAccessPartnerOrder');
          if (response) {
            // TODO put right URLs
            this.$router.push('/access-partner/success');
          }
          this.submitButtonDisabled = false;
        }
      } catch (error) {
        console.error(error);
        this.submitButtonDisabled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables/_colors.scss';
@import '../../styles/variables/_sizes.scss';
@import '../../styles/theme/_default.scss';

$char-w: 1ch;
$gap: .4*$char-w;
$n-char: 4;
$in-w: $n-char*($char-w + $gap);

#access-partner {
  #recaptcha {
    > div {
      margin: 0px auto 30px auto;
    }
    margin-bottom: 20px;
    margin-left: 20%;
  }
  margin-top: 40px;
  .form {
    padding: 40px 20px 0px 20px;
  }
  button {
    display: block;
    margin: 0 auto;
  }
  #email-verify-button {
    margin-top: 21px;
  }
  .link-button {
    height: 25px;
  }
  .invalid-feedback {
    position: absolute;
  }
  #verification-code {
    width: 170px;
    height: 55px;
    margin: 2em auto;
    border: 1px solid;
    padding: 0px 25px 5px 30px;
    border-radius: 8px;
    &:focus-within {
      border: 1px solid #21BECE;
    }
    .invalid-feedback {
      display: inline-flex !important;
      position: relative;
      margin-left: -50px;
      margin-top: 10px;
      width: 250px;
    }
    #verification-code-field {
      border-radius: 0 !important;
      border: 0 !important;
      padding: 0 !important;
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 36px !important;
      line-height: 43px;
      display: block;
      width: $in-w;
      background: repeating-linear-gradient(90deg,
        dimgrey 0, dimgrey $char-w,
        transparent 0, transparent $char-w + $gap)
        0 100%/ #{$in-w - $gap} 3px no-repeat;
      letter-spacing: $gap;
      &:focus {
        outline: none;
        box-shadow: none !important;
      }
    }
  }
}
</style>
